/* ScrollIndicator.css */
.scroll-indicator {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.scroll-item {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  padding: 10px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.scroll-item.active {
  opacity: 1;
  color: #ff6600; /* Use one of your chosen colors */
}
