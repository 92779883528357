.footer {
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  color: #000000;
  box-sizing: border-box;
  margin-top: 40px;
  overflow: hidden;
}

.footer-background {
  background-image: url('../../public/images/footer-background-wTrooper.jpg');
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  z-index: 1;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; /* Align items to the right */
  padding: 48px 20px;
  position: relative;
  z-index: 2;
}

.footer-section {
  flex: 1 1 200px;
  margin: 10px 10px; /* Adjust margin for better spacing */
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-right: auto; /* Push the logo to the left */
}

.footer-logo-img {
  max-width: 75px;
}

.company-name {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.footer-links h2,
.legal h2,
.resources h2,
.quick-links h2 {
  margin-bottom: 10px;
}

.footer-links ul,
.legal ul,
.resources ul,
.quick-links ul {
  list-style: none;
  padding: 0;
}

.footer-links li,
.legal li,
.resources li,
.quick-links li {
  margin-bottom: 5px;
}

.footer-links a,
.legal a,
.resources a,
.quick-links a {
  color: #ff6600;
  text-decoration: none;
}

.footer-links a:hover,
.legal a:hover,
.resources a:hover,
.quick-links a:hover {
  text-decoration: underline;
}

.footer-copyright {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  position: relative;
  z-index: 2;
}

@media (max-width: 768px) {
  .footer-content {
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-section {
    flex: 1 1 45%;
    margin: 10px 5px;
  }

  .footer-logo {
    order: 1; /* Move logo to the last */
    flex: 1 1 100%;
    margin-bottom: 20px;
    align-items: center;
  }

  .footer-copyright {
    order: 2; /* Move copyright to the last */
    flex: 1 1 100%;
    text-align: center;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .footer-content {
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-section {
    flex: 1 1 45%; /* Two sections per row */
    margin: 10px 5px;
  }

  .footer-logo {
    order: 1; /* Move logo to the last */
    flex: 1 1 100%;
    margin-bottom: 20px;
    align-items: center;
  }

  .footer-copyright {
    order: 2; /* Move copyright to the last */
    flex: 1 1 100%;
    text-align: center;
    margin-top: 10px;
  }
}
