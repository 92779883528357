.full-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-color: #f4f4f4; /* Light gray background */
  padding: 20px;
  box-sizing: border-box;
}

.setup-page-container {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.setup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.setup-header h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.setup-content .section-title {
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.field {
  margin-bottom: 20px;
  position: relative;
}

.field label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.readonly-input {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.button-group button {
  padding: 10px 15px;
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.button-group button:hover {
  background-color: #0056b3;
}

.separator {
  height: 1px;
  background-color: #ddd;
  margin: 30px 0;
}

.upload-fields {
  display: flex;
  flex-direction: column;
}

.upload-fields div {
  margin-bottom: 20px;
}

.upload-fields label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.upload-fields input[type="file"] {
  padding: 5px;
  font-size: 14px;
}
