.login-container {
    font-family: 'Montserrat', sans-serif;
    margin-top: 100px; /* Adjust for fixed header */
    color: white;
  }
  
  .login-form-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    background-color: #002244; /* Background color for the form section */
    padding: 20px;
    border-radius: 10px;
  }
  
  .login-form-section h2 {
    margin-bottom: 20px;
  }
  
  .login-form {
    background-color: #003366;
    color: white;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 500px; /* Reduced width for the form */
    display: flex;
    flex-direction: column;
  }
  
  .login-form label {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .login-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    width: calc(100% - 20px); /* Adjusted to avoid touching the border */
  }
  
  .captcha-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .captcha-code {
    background-color: #fff;
    color: #000;
    padding: 5px 10px;
    margin-bottom: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.2em;
    letter-spacing: 2px;
    border-radius: 5px;
  }
  
  .login-button {
    background-color: #ffcc00; /* Contrast color */
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .login-button:hover {
    background-color: #e6b800; /* Hover effect for contrast color */
  }

  .forgot-password-link,
  .back-button {
    background: none;
    border: none;
    color: white;
    font-size: 0.9em;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 10px;
    align-self: flex-end; /* Aligning buttons to the right */
  }

  .back-button {
    margin-right: auto; /* Push back button to the right */
  }

  .register-link {
    margin-top: 10px;
    font-size: 0.9em;
    color: white;
  }

  .register-link a {
    color: #ffcc00;
    text-decoration: underline;
  }

  .error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 10px;
  }

  .success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .success-message {
    text-align: center;
  }

  .success-message p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .success-message button {
    background-color: #ffcc00;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    border-radius: 5px;
    cursor: pointer;
  }
