.dashboard-wrapper {
  padding-top: 80px; /* Adjust according to the header height */
}

.dashboard-container {
  display: flex;
  height: calc(100vh - 80px); /* Adjust according to the header height */
  padding: 20px;
  box-sizing: border-box;
}

.side-menu {
  width: 250px;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  height: calc(100vh - 120px); /* Adjust according to the header and footer height */
  box-sizing: border-box;
  overflow-y: auto;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.side-menu ul li {
  padding: 10px 0;
}

.side-menu ul li a {
  text-decoration: none;
  color: #333;
  display: block;
}

.side-menu ul li a:hover {
  background-color: #ddd;
  padding-left: 10px;
  border-radius: 5px;
}

.logout-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  display: block;
  font-size: 16px;
  margin: 20px 0 0 0; /* Adjust margin to align with menu items */
  cursor: pointer;
  border-radius: 5px;
}

.logout-button:hover {
  background-color: #e60000;
}

.main-content {
  flex: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('/public/images/space-background.jpg');
  padding: 20px;
  margin-left: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-message {
  text-align: center;
}

.welcome-message h2 {
  color: #fff;
}

.dashboard-full-logo {
  width: 400px; /* Adjust the size of the logo */
  opacity: 0.1; /* Set opacity to make it less prominent */
}

@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
    height: auto; /* Allow content to define height */
  }

  .side-menu {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    overflow-x: auto; /* Allow horizontal scrolling */
    box-sizing: border-box;
  }

  .side-menu ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 0;
  }

  .side-menu ul li {
    padding: 10px;
  }

  .side-menu ul li a {
    padding: 10px;
  }

  .logout-button {
    margin: 0 10px; /* Add margin to prevent overlap */
    padding: 10px;
    align-self: center;
  }

  .main-content {
    margin-left: 0;
    margin-top: 20px;
    width: 100%; /* Full width */
  }
}
