.policy-container {
    padding: 20px;
    margin: 100px auto; /* Margin adjust for fixed header */
    max-width: 800px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .policy-container h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  .policy-container h2 {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .policy-container p, .policy-container ul {
    margin-bottom: 1em;
  }
  
  .policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .policy-container ul li {
    margin-bottom: 0.5em;
  }
  
  .policy-container strong {
    font-weight: bold;
  }
  