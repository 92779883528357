/* src/components/NotFound.css */
.not-found {
    position: relative;
    height: 100vh;
    width: 100vw;
    background: url('../../public/images/404-image.jpg') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .not-found-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff; /* White text for visibility over the image */
  }
  
  .not-found-title {
    font-size: 100px;
    font-weight: bold;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Shadow for better readability */
  }
  
  .not-found-message {
    font-size: 24px;
    margin: 10px 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Shadow for better readability */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .not-found-title {
      font-size: 80px;
    }
  
    .not-found-message {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .not-found-title {
      font-size: 60px;
    }
  
    .not-found-message {
      font-size: 16px;
    }
  }
  