.services {
    font-family: 'Montserrat', sans-serif;
    margin-top: 100px; /* Adjust for fixed header */
    color: white;
  }
  
  .services-section {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('/public/images/space-background.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
  }
  
  .section {
    padding: 60px 20px;
    margin-bottom: 40px;
    border-radius: 10px;
    text-align: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    min-height: 50vh; /* Adjusted for Services */
  }
  
  .service-card {
    background-color: #003366;
    color: white;
    border-radius: 15px;
    padding: 40px;
    display: flex;
    align-items: center;
    margin: 20px;
    flex-direction: row;
  }
  
  .service-card-title {
    flex: 1; /* Takes up 30-40% of horizontal space */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  
  .service-card-image-container {
    width: 70px;
    height: 70px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 50%; /* Rounded image */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Light background color to prevent flicker */
  }
  
  .service-card-image-container img {
    width: 70px; /* Fixed width */
    height: 70px; /* Fixed height */
    object-fit: cover; /* Ensures image covers the container */
    filter: brightness(0.8); /* Adds a cool overlay effect */
    transition: filter 0.3s;
  }
  
  .service-card-image-container img:hover {
    filter: brightness(1); /* Removes overlay effect on hover */
  }
  
  .service-card-title h3 {
    font-size: 1.5em;
    text-align: center;
  }
  
  .service-card ul {
    flex: 2; /* Takes up 60-70% of horizontal space */
    list-style-type: none;
    text-align: left;
    padding: 0;
    margin: 0 auto; /* Center the text content */
  }
  
  .service-card ul li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .service-card {
      flex-direction: column;
      text-align: center;
    }
  
    .service-card-title {
      flex-direction: column;
      align-items: center;
      margin-right: 0;
    }
  
    .service-card-image-container {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .service-card-title h3 {
      margin-top: 10px; /* Add top margin for spacing */
    }
  
    .service-card ul {
      text-align: center; /* Keep text left-aligned */
      margin: 0; /* Remove auto margin */
    }
  }
  