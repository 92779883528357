.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    border: 1px solid #ddd; /* Add border for better visibility */
  }
  
  .card h3 {
    margin-top: 0;
    color: #333;
  }
  
  .chart-summary-grid {
    display: flex;
    flex-direction: column;
  }
  
  .chart-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px; /* Add margin between sections */
  }
  
  .chart {
    width: 100%;
    height: 200px;
    margin-bottom: 10px; /* Reduced margin */
  }
  
  .summary {
    width: 100%;
  }
  
  .summary-item {
    margin-bottom: 10px;
  }
  
  .summary-item strong {
    color: #333;
  }
  
  /* Slick Carousel Styles */
  .slick-prev, .slick-next {
    color: #333;
  }
  
  .slick-dots li button:before {
    color: #333;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .chart-container {
      flex: 1 1 100%; /* Full width on smaller screens */
    }
  }
  