.contact-us {
  font-family: 'Montserrat', sans-serif;
  margin-top: 100px; /* Adjust for fixed header */
  color: white;
}

.contact-section {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('/public/images/space-background.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  padding: 60px 20px;
  border-radius: 10px;
  text-align: center;
  background-size: cover;
  background-attachment: fixed;
}

.contact-form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  background-color: #002244; /* Background color for the form section */
  padding: 20px;
  border-radius: 10px;
}

.contact-form-section h2 {
  margin-bottom: 20px;
  color: white;
}

.contact-form {
  background-color: #003366;
  color: white;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  width: calc(100% - 20px); /* Adjusted to avoid touching the border */
}

.contact-form textarea {
  resize: vertical;
  min-height: 100px;
}

.captcha-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.captcha-code {
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.2em;
  letter-spacing: 2px;
  border-radius: 5px;
}

.contact-button {
  background-color: #ffcc00; /* Contrast color */
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #e6b800; /* Hover effect for contrast color */
}

.faq-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  background-color: #002244; /* Background color for the FAQ section */
  padding: 20px;
  border-radius: 10px;
}

.faq-section h2 {
  margin-bottom: 20px;
  color: white;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.faq-item {
  background-color: #004080; /* Darker shade of original color */
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: left;
  width: 90%;
}

.faq-question {
  background: none;
  border: none;
  color: white;
  font-size: 1.2em;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.faq-answer {
  margin-top: 10px;
  font-size: 1em;
  text-align: left;
}

@media (max-width: 768px) {
  .contact-form,
  .faq-section h2,
  .faq-item {
    width: 90%;
  }
}
