.full-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%; /* Full viewport height */
    width: 100%; /* Full viewport width */
    background-color: #f4f4f4; /* Same background color as setup-container */
    border-radius: 10px;
    padding: 20px;
  }
  

  .tests-page-container {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
  }
  
  .tests-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .tests-header h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  .run-all-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .run-all-button:hover {
    background-color: #0056b3;
  }
  
  .tests-list {
    width: 100%;
  }
  
  .parent-test {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .parent-test-header {
    cursor: pointer;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .parent-test-header h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
    flex-grow: 1;
  }
  
  .test-count {
    background-color: #ccc;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .test-count.hidden {
    display: none;
  }
  
  .parent-test-details {
    display: none; /* Ensure it's hidden by default */
    padding-left: 20px;
  }
  
  .parent-test-details.collapsed {
    display: block;
  }
  
  .child-test {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 5px 0;
  }
  
  .child-test button {
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .child-test button:hover {
    background-color: #218838;
  }
  
  .no-children {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 5px 0;
    text-align: center;
    color: #666;
  }