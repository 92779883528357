.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;  /*#003366, #061c37;*/
  color: black;
  padding: 20px 20px;  /* Increase padding for more height */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
  color: black;
  font-size: 24px;
  text-decoration: none;
}

.small-logo {
  max-height: 40px; /* Adjust as needed */
  margin-right: 10px;
}

.company-name {
  font-size: 24px; /* Adjust as needed */
}

nav {
  display: flex;
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: black;
  text-decoration: none;
  font-size: 18px;
  white-space: nowrap;
}

nav ul li a:hover {
  color: #cccccc;
}

/* Mobile styles */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.hamburger .line {
  width: 100%;
  height: 3px;
  background-color: black;
  transition: transform 0.3s, opacity 0.3s;
}

.hamburger.open .line:nth-child(1) {
  transform: translateY(12px) rotate(45deg);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: translateY(-12px) rotate(-45deg);
}

@media (max-width: 768px) {
  nav {
    position: fixed;
    top: 70px;
    right: 0;
    background-color: #f5f5f5;  /*#003366, #061c37;*/
    height: calc(100% - 70px);
    width: 250px;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  nav.open {
    transform: translateX(0);
  }

  nav ul {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul li {
    width: 100%;
    padding: 15px 0; /* Add padding to create spacing between items */
  }

  nav ul li a {
    padding: 15px;
    width: 100%;
  }

  .hamburger {
    display: flex;
  }
}
