/* CookieConsentPopup.css */

.cookie-consent-popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .cookie-consent-popup .content {
    text-align: center;
  }
  
  .cookie-consent-popup h2 {
    margin-bottom: 10px;
  }
  
  .cookie-consent-popup p {
    margin-bottom: 20px;
  }
  
  .cookie-consent-popup .buttons button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cookie-consent-popup .buttons button:hover {
    background-color: #0056b3;
  }
  