.full-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
}

.reports-page-container {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.reports-header h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.reports-list {
  width: 100%;
}

.parent-report {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.parent-report-header {
  cursor: pointer;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.parent-report-header h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
  flex-grow: 1;
}

.report-count {
  background-color: #ccc;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-count.hidden {
  display: none;
}

.parent-report-details {
  display: none;
  padding-left: 20px;
}

.parent-report-details.collapsed {
  display: block;
}

.child-report {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 5px 0;
}

.child-report-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.child-report span {
  flex: 1;
}

.report-buttons {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.child-report button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.child-report button:hover {
  background-color: #0056b3;
}

.report-timestamp {
  font-size: 12px;
  color: #666;
  text-align: right;
  margin-top: 5px;
}

@media (max-width: 600px) {
  .child-report-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .report-buttons {
    margin-top: 5px;
  }

  .report-timestamp {
    text-align: left;
  }
}
