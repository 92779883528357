.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 600px;
    border: 1px solid #ddd; /* Add border for better visibility */
  }
  
  .card h3 {
    margin-top: 0;
    color: #333;
  }
  
  .chart-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Distribute items horizontally */
    align-items: center; /* Center items vertically */
    flex-wrap: wrap; /* Allow items to wrap if necessary */
  }
  
  .summary-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 20px; /* Space between items */
  }
  
  .color-box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 3px;
  }
  
  .duration {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .summary {
      justify-content: center; /* Center items on smaller screens */
    }
  
    .summary-item {
      margin-right: 10px; /* Adjust spacing for smaller screens */
    }
  }
  