.home {
  font-family: 'Montserrat', sans-serif;
  margin-top: 100px; /* Adjust for fixed header */
  color: white;
}

.section {
  padding: 60px 20px;
  margin-bottom: 40px;
  border-radius: 10px;
  text-align: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  min-height: 50vh; /* Adjusted for Services */
}

.welcome-section, .what-section, .why-section, .how-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
}

.welcome-section {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('/public/images/space-background.jpg');
}

.what-section, .why-section {
  background-color: #003366; /* Darker shade of original color */
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  text-align: center;
}

.how-section {
  background-color: #121212; /* Darker shade of original color */
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  text-align: center;
}

.section h1, .section h2{
  font-size: 48px;
  margin-bottom: 20px;
}

.section h3 {
  font-size: 32px;
  margin-bottom: 20px;
}

.section p {
  font-size: 20px;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.why-content, .how-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.why-content ul {
  text-align: center;
  list-style-type: none;
  padding: 0;
}

.why-content ul li {
  margin-bottom: 10px;
  font-size: 18px;
}

.how-diagram {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-diagram img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.full-logo {
  max-width: 400px; /* Increase size as needed */
  margin-bottom: -60px;
}

/* New styles for interactive images */
.interactive-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  position: relative;
  flex-direction: row;
  width: 100%;
}

.image-container {
  position: relative;
  width: 35%;
  margin-right: 20px;
}

.background-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px;
  opacity: 0.8;
  transition: opacity 0.5s;
}

.cover-image.hidden {
  opacity: 0;
}

.floating-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s;
}

.floating-button:hover {
  transform: scale(1.1);
}

.text-container {
  max-width: 50%;
  text-align: left;
}

.floating-image {
  width: 90%; /* Adjusted width for better fit */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.text-content {
  max-width: 50%;
  text-align: left;
}

/* Styles for smaller titles */
.interactive-image h3 {
  font-size: 24px;
}

/* Additional spacing between sections and subsections */
.section {
  margin-bottom: 60px;
}

.interactive-image {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .interactive-image {
    flex-direction: column;
    text-align: center;
  }

  .image-container {
    width: 100%;
    margin-right: 0;
  }

  .text-container {
    max-width: 100%;
    margin-top: 20px;
  }

  .floating-button {
    top: auto;
    right: auto;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
