.full-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
}

.insights-page-container {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  /*max-width: 600px;*/
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.insights-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.insights-header h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.insights-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/*
.insight-card {
  flex: 1 1 calc(40% - 20px);
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}
  */

.no-insights {
  text-align: center;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .insight-card {
    flex: 1 1 100%;
  }
}
