.pricing {
  font-family: 'Montserrat', sans-serif;
  margin-top: 100px; /* Adjust for fixed header */
  color: white;
}

.pricing-section {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('/public/images/space-background.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  padding: 60px 20px;
  border-radius: 10px;
  text-align: center;
  background-size: cover;
  background-attachment: fixed;
}

.feature-section {
  background-color: #003366; /* Darker shade of original color */
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  text-align: center;
}

.feature-pills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px auto;
  max-width: 80%;
}

.pill {
  background-color: #0055a5;
  color: white;
  border-radius: 50px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.pill.highlighted {
  background-color: #ffcc00;
  color: black;
}

.feature-details {
  background-color: #003366;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  margin: 20px auto;
  max-width: 80%;
}

.feature-details ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-details ul li {
  margin: 10px 20px;
  flex: 1;
  min-width: 200px;
  text-align: center;
}

.feature-info {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.feature-info > div {
  flex: 1;
  min-width: 200px;
}

.comparison-section {
  background-color: #003366; /* Darker shade of original color */
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  text-align: center;
}

.comparison-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.comparison-card {
  background-color: #0055a5; /* Accent color matching palette */
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  max-width: 300px;
  text-align: center;
}

.comparison-card h3 {
  text-align: center;
  margin-bottom: 10px;
}

.comparison-card ul {
  list-style-type: none;
  padding: 0;
}

.comparison-card ul li {
  margin-bottom: 10px;
}

.cta-section {
  background-color: #171a1c; /* Darker shade of original color */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 20px;
}

.contact-button {
  background-color: #ffcc00; /* Contrast color */
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #e6b800; /* Hover effect for contrast color */
}

@media (max-width: 768px) {
  .pricing-cards, .comparison-cards {
    flex-direction: column;
    align-items: center;
  }
  
  .pricing-card, .comparison-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .feature-details ul {
    flex-direction: column;
    align-items: center;
  }

  .feature-info {
    flex-direction: column;
    align-items: center;
  }
}